import React, { useEffect, useState } from "react"
import queryString from "query-string"
import { Layout, SEO, CJumbotron, CBreadCrumb, MicroCmsLayout, CTab } from "../../../components/_index"
import ActivityLayout from "../../../components/_extra/activity_layout"
import AestheticLayout from "../../../components/_extra/aesthetic_layout"
import OiwaiLayout from "../../../components/_extra/oiwai_layout"

// markup
const SubPage = ({ location }: any) => {
  const { contentId, draftKey } = queryString.parse(location.search);
  const [data, setData] = useState<any | null>(null);
  useEffect(() => {
    fetch(`https://${process.env.MICRO_CMS_SERVICE_DOMAIN}.microcms.io/api/v1/plan_srph/${contentId}?draftKey=${draftKey}`, {
      headers: {
        'X-MICROCMS-API-KEY': process.env.MICRO_CMS_API_KEY || "",
      },
    })
      .then(res => res.json())
      .then(res => setData({ res }));
  }, []);

  if (data === null) {
    return null;
  }

  return (
    <Layout>
      <SEO title="プレビュー"
        description="プレビュー"
        ogtype={"blog"}
      />
      {
        (data.res.classification[0] == 'エステ') ?
          <AestheticLayout data={data.res} /> :
          (data.res.classification[0] == 'お祝い' || data.res.classification[0] == 'プロポーズ') ?
            <OiwaiLayout data={data.res} /> :
            <ActivityLayout data={data.res} />
      }
    </Layout>
  )
}

export default SubPage;
